import { defineStore } from 'pinia';
import type { Webrtc, UserAgent, Transfer, Session } from '@/types';
import { markRaw } from 'vue';

export const useWebrtcStore = defineStore('webrtc', {
  state: (): Webrtc => ({
    sessionActive: {},
    sessionsArray: [],
    userAgentActive: {},
    userAgentArray: [],
    isTransferring: false,
    transfer: {},
    sessionMap: new Map(),
    openDialpad: {},
  }),
  getters: {
    hasSessions: (state) => state.sessionsArray.length > 0,
  },
  actions: {
    setSessionActive(session: Session) {
      this.sessionActive = markRaw(session);
    },
    setOpenDialpad(openDialpad: boolean) {
      this.openDialpad.newStatus = new Date().getTime();
      this.openDialpad.open = openDialpad;
    },
    setSessionsArray(session: Session) {
      this.sessionsArray.push(markRaw(session));
      this.sessionMap.set(session.id, session);
    },

    removeSession(session: Session) {
      this.sessionMap.delete(session.id);
      const index = this.sessionsArray.findIndex((s) => s.id === session.id);
      if (index > -1) {
        this.sessionsArray.splice(index, 1);
      }
      if (this.sessionsArray.length > 0) {
        return this.setSessionActive(
          this.sessionsArray[this.sessionsArray.length - 1],
        );
      }

      this.setSessionActive({});
    },

    setUserAgentActive(userAgent: UserAgent) {
      this.userAgentActive = markRaw(userAgent);
    },

    setUserAgentArray(userAgent: UserAgent) {
      const index = this.userAgentArray.findIndex(
        (u) => u.number === userAgent.number,
      );
      if (index === -1) {
        this.userAgentArray.push(markRaw(userAgent));
      }
    },

    removeUserAgent(number: string) {
      const index = this.userAgentArray.findIndex((u) => u.number === number);
      if (index !== -1) {
        this.userAgentArray.splice(index, 1);
      }
      if (this.userAgentArray.length > 0) {
        return this.setUserAgentActive(
          this.userAgentArray[this.userAgentArray.length - 1],
        );
      }

      this.setUserAgentActive({});
    },

    unRegisterAll() {
      this.userAgentArray.forEach((userAgent) => {
        this.removeUserAgent(userAgent.number);
      });
    },

    setIsTransferring(isTransferring: boolean) {
      this.isTransferring = isTransferring;
    },

    setTransfer(transfer: Transfer) {
      this.transfer = markRaw(transfer);
    },

    hasUserAgentByNumber(number: string) {
      return !!this.userAgentArray.find((u) => u.number === number);
    },

    findSessionById(id: string) {
      return this.sessionMap.get(id);
    },

    findIndexSessionById(id: string) {
      return this.sessionsArray.findIndex((session) => session.id === id);
    },

    findSessionStartWithByCode(code: string) {
      return this.sessionsArray.find((session) =>
        session.remote_identity.uri.user.startsWith(code),
      );
    },

    setRemoteAudioStream(id: string, remoteAudioStream: MediaStream) {
      const index = this.findIndexSessionById(id);

      this.sessionsArray[index].remoteAudioStream = remoteAudioStream;
    },

    endAllCalls() {
      for (const session of this.sessionsArray) {
        session.terminate();
      }
    },
  },
});
