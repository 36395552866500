import { defineStore } from 'pinia';
import type { ArrayChatMessageVars } from '@/types/chat-message-vars';
import type { ChatMessage, ChatMessageSocket } from '@/utils/db/types';
import { useArray } from '@/composables';

export const useChatMessageVars = defineStore('chat-message-vars', {
  state: (): ArrayChatMessageVars => ({
    users: [],
    current_chat: {
      user: 0,
      messages: [],
    },
    pendingConferencesMessage: [],
    pendingCallsMessage: [],
  }),
  getters: {
    getUserData(state) {
      return (userId: number) =>
        state.users.find((user) => user.user === userId);
    },
    getUserConference(state) {
      return (messageId: string) =>
        state.pendingConferencesMessage.find(
          (message) => message.id === messageId,
        );
    },
    getPendingCallMessage(state) {
      return (sessionId: string) =>
        state.pendingCallsMessage.find(
          (pendingMessage) => pendingMessage.sessionId === sessionId,
        );
    },
  },
  actions: {
    setDataUser(userId: number, dataFirst: string, dataLast: string) {
      const userIndex = this.users.findIndex((user) => user.user === userId);
      if (userIndex !== -1) {
        this.users[userIndex].data_message_first = dataFirst;
        this.users[userIndex].data_message_last = dataLast;
        return;
      }

      this.users.push({
        user: userId,
        data_message_first: dataFirst,
        data_message_last: dataLast,
      });
    },
    initializeNewChatUser(user: number) {
      this.current_chat.user = user;
    },
    addChatMessage(message: ChatMessage) {
      const temporaryMessages = this.current_chat.messages;
      const index = temporaryMessages.findIndex(
        (messagePinia) => messagePinia.id === message.id,
      );

      if (index !== -1) {
        temporaryMessages[index] = message;
      } else {
        temporaryMessages.push(message);
      }

      this.current_chat.messages = [...temporaryMessages];
    },
    setChatMessages(messages: ChatMessage[]) {
      this.current_chat.messages = messages;
    },
    removeChatMessage(id: string) {
      const { removeElementByIndex, findIndexByValue } = useArray(
        this.current_chat.messages,
      );

      const index = findIndexByValue('id', id);
      removeElementByIndex(index);
    },
    addUserConference(message: ChatMessageSocket) {
      this.pendingConferencesMessage.push(message);
    },
    removeUserConference(message: ChatMessageSocket) {
      const id = this.pendingConferencesMessage.findIndex(
        (messageConference) => messageConference.id === message.id,
      );

      if (id !== -1) {
        return this.pendingConferencesMessage.splice(id, 1);
      }
    },
    addPendingCallMessage(sessionId: string, message: ChatMessageSocket) {
      this.pendingCallsMessage = [
        ...this.pendingCallsMessage,
        { sessionId, message },
      ];
    },
    removePendingCallMessage(sessionId: string) {
      const id = this.pendingCallsMessage.findIndex(
        (call) => call.sessionId === sessionId,
      );

      if (id !== -1) {
        const temporaryCalls = this.pendingCallsMessage;
        temporaryCalls.splice(id, 1);
        this.pendingCallsMessage = [...temporaryCalls];
      }
    },
  },
});
