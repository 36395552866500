<template>
  <RouterView />
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import { onMounted } from 'vue';
import {
  useLanguageStore,
  useDarkModeStore,
  useOsStore,
  useUserStore,
} from '@/stores';
import { axiosDefault } from '@/utils';
import { useRegisterSW } from 'virtual:pwa-register/vue';
import app from '../package.json';
// @ts-expect-error:next-line
import { jam } from '@jam.dev/sdk';
import * as Sentry from '@sentry/vue';

const darkModeStore = useDarkModeStore();
const languageStore = useLanguageStore();
const osStore = useOsStore();
const userStore = useUserStore();

onMounted(() => {
  darkModeStore.hasDarkMode();
  languageStore.setLanguage();
  axiosDefault();
  osStore.setDetectOS();

  const appVersion = app.version;

  Sentry.setUser({
    id: userStore.user.userId.toString(),
    email: userStore.user.email,
    username: userStore.user.userName,
  });

  Sentry.setContext('version', {
    appVersion,
  });
});

function jamMetadata() {
  jam.metadata(() => ({
    user: {
      id: userStore.user.userId,
      name: userStore.user.userName,
      accountCode: userStore.currentAccount.accountCode,
    },
    timeSincePageLoad: performance.now(),
    appVersion: app.version,
  }));
}

onMounted(() => {
  jamMetadata();

  const { updateServiceWorker, needRefresh } = useRegisterSW();

  if (needRefresh.value) {
    updateServiceWorker();
    location.reload();
  }
});
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
</style>
