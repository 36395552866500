export enum Account {
  Admin = 'Account Admin',
  CSR = 'Account Admin',
  WhiteLabel = 'Account Admin',
  Tech = 'Account Admin',
  Customer = 'Account Admin',
  Conference = 'Standard User',
  SubAccount = 'Standard User',
  Number = 'Standard User',
}
