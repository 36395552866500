import { computed, ref } from 'vue';
import type {
  ImportedContacts,
  ProviderImportedContact,
  SuccessSetImportedContactsResponseData,
  EmailProvider,
  SuccessSetImportedDirectoryContactsResponseData,
} from '@/types';
import { useArray } from '@/composables';
import { extractNumber, phoneMasked } from '@/utils';

export function useImportedContacts() {
  const importedContacts = ref<ImportedContacts[]>([]);
  const emailsProviderSynced = ref<EmailProvider[]>([]);

  const countImportedContactsByProvider = computed(() => {
    return (provider: ProviderImportedContact) => {
      return importedContacts.value.filter(
        (contact) => contact.provider === provider,
      ).length;
    };
  });

  function getImportedContact(
    contact: SuccessSetImportedContactsResponseData,
  ): ImportedContacts {
    return {
      id: contact.id,
      userId: contact.user_id,
      emails: contact.emails.map((email) => ({
        address: email,
        canEdit: !(email === contact.email_from_pbx),
      })),
      firstName: contact.first_name,
      lastName: contact.last_name,
      phones: contact.phones.map((phone) => ({
        tag: phone.tag,
        number: phone.number,
        canEdit: !phone.number_from_pbx,
      })),
      provider: contact.provider,
      emailSourceProvider: contact.email_source_provider,
      jobTitle: contact.job_title,
      company: contact.company,
      country: contact.country,
      state: contact.state,
      street: contact.street,
      city: contact.city,
      postcode: contact.postcode,
      type: contact.type as 'imported' | 'local',
      isDirectory: !!contact.contact_user_id,
      isUpdated: true,
      directory: {
        id: contact.contact_user_id ? Number(contact.contact_user_id) : null,
        accountCode: contact.contact_account_code
          ? Number(contact.contact_account_code)
          : null,
      },
    };
  }

  function getDirectoryContact(
    contact: SuccessSetImportedDirectoryContactsResponseData,
    userId: string,
  ): ImportedContacts {
    return {
      id: Number(`${contact.user_id}${contact.account_code}`),
      userId,
      emails: [{ address: contact.email, canEdit: false }],
      firstName: contact.user_name,
      lastName: '',
      phones: contact.assigned_numbers.map((n) => ({
        tag: 'work',
        number: n.number,
        canEdit: false,
      })),
      provider: '',
      emailSourceProvider: '',
      jobTitle: '',
      company: '',
      country: '',
      state: '',
      street: '',
      city: '',
      postcode: '',
      type: 'local',
      isDirectory: true,
      isUpdated: false,
      directory: {
        id: Number(contact.user_id),
        accountCode: Number(contact.account_code),
      },
    };
  }

  function setImportedContacts(
    contact: SuccessSetImportedContactsResponseData,
  ) {
    importedContacts.value.push(getImportedContact(contact));
  }

  function setImportedDirectoryContacts(
    contact: SuccessSetImportedDirectoryContactsResponseData,
    userId: string,
  ) {
    importedContacts.value.push(getDirectoryContact(contact, userId));
  }

  function updateContact(
    id: number,
    contact: SuccessSetImportedContactsResponseData,
  ) {
    const { findIndexByValue } = useArray(importedContacts.value);

    const index = findIndexByValue('id', id);

    if (index !== -1) {
      importedContacts.value[index] = getImportedContact(contact);

      if (!importedContacts.value[index].isUpdated) {
        importedContacts.value[index].isUpdated = true;
        importedContacts.value[index].id = contact.id;
      }
    }
  }

  function deleteContact(id: number) {
    const { findIndexByValue, removeElementByIndex } = useArray(
      importedContacts.value,
    );

    const index = findIndexByValue('id', id);

    if (index !== -1) {
      removeElementByIndex(index);
    }
  }

  function sortImportedContactsByNameAsc() {
    importedContacts.value.sort((a, b) => {
      const aFullName = `${a.firstName} ${a.lastName}`.trim();
      const bFullName = `${b.firstName} ${b.lastName}`.trim();

      return aFullName.localeCompare(bFullName);
    });
  }

  function setEmailProviderAccount(
    provider: ProviderImportedContact,
    email: string,
  ) {
    const emailProvider = getEmailProviderAccount(provider);
    if (emailProvider === undefined) {
      return emailsProviderSynced.value.push({
        provider,
        email,
      });
    }

    emailProvider.email = email;
  }

  function getEmailProviderAccount(
    provider: ProviderImportedContact,
  ): EmailProvider | undefined {
    return emailsProviderSynced.value.find(
      (item) => item.provider === provider,
    );
  }

  function setSourceEmailProvider() {
    emailsProviderSynced.value = [];

    for (const { emailSourceProvider, provider } of importedContacts.value) {
      const hasEmailSourceProvider = emailsProviderSynced.value.find(
        (emailProvider) => emailProvider.provider === provider,
      );

      if (!hasEmailSourceProvider) {
        emailsProviderSynced.value.push({
          email: emailSourceProvider,
          provider,
        });
      }
    }
  }

  function removeImportedContactsByProvider(provider: ProviderImportedContact) {
    importedContacts.value = importedContacts.value.filter(
      (contact) => contact.provider !== provider,
    );
    setSourceEmailProvider();
  }

  const getImportedContactsById = computed(() => {
    return (id: number) => {
      return importedContacts.value.find((contact) => contact.id === id);
    };
  });

  const getImportedContactsByDirectoryId = computed(() => {
    return (id: number) => {
      return importedContacts.value.find(
        (contact) => contact.directory.id === id,
      );
    };
  });

  function setImportedContactsFavorite(id: number) {
    const index = importedContacts.value.findIndex(
      (contact) => contact.id === id,
    );

    if (index !== -1) {
      importedContacts.value[index].favorite = true;
    }
  }

  const favoriteImportedContacts = computed(() =>
    importedContacts.value.filter((contact) => contact.favorite),
  );

  const findImportedContactByNumber = computed(() => {
    return (number: string) => {
      const contacts: {
        id: ImportedContacts['id'];
        phone: { tag: string; number: string; fullName: string };
      }[] = [];
      for (const contact of importedContacts.value) {
        const fullName = `${contact.firstName} ${contact.lastName}`.trim();
        if (!contact.phones) {
          continue;
        }
        for (const phone of contact.phones) {
          contacts.push({
            id: contact.id,
            phone: { ...phone, fullName },
          });
        }
      }
      return contacts.find(
        (contact) =>
          phoneMasked(extractNumber(contact.phone.number)) ===
          phoneMasked(extractNumber(number)),
      );
    };
  });

  function getImportedContactByNumber(number: string) {
    return importedContacts.value.find((contact) =>
      contact.phones.some((phone) => phone.number === number),
    );
  }

  function findDirectoryNumbersByNumber(id: number) {
    const directory = getImportedContactsByDirectoryId.value(id);
    return directory ? directory.phones.map((phone) => phone.number) : [];
  }

  return {
    importedContacts,
    countImportedContactsByProvider,
    emailsProviderSynced,
    getImportedContactsById,
    getImportedContactsByDirectoryId,
    setImportedContacts,
    setEmailProviderAccount,
    getEmailProviderAccount,
    removeImportedContactsByProvider,
    setSourceEmailProvider,
    sortImportedContactsByNameAsc,
    setImportedContactsFavorite,
    setImportedDirectoryContacts,
    favoriteImportedContacts,
    updateContact,
    deleteContact,
    findImportedContactByNumber,
    getImportedContactByNumber,
    findDirectoryNumbersByNumber,
  };
}
