import { usePermission } from '@vueuse/core';

export const microphoneAccess = usePermission('microphone');
export const speakerAccess = usePermission('speaker');
export const cameraAccess = usePermission('camera');
export const notificationsAccess = usePermission('notifications');

export function notPlayingSongWithKeyboard() {
  navigator.mediaSession.setActionHandler('play', function () {
    console.log('You can not play the song here...');
  });
}
