import { defineStore } from 'pinia';
import type { Meeting, Message, Phone } from '@/types';

const maximumNumberOfToasts = 3;

interface State {
  toasts: Map<string, Meeting | Message | Phone>;
  meetings: Meeting[];
  messages: Message[];
  phones: Phone[];
}

export const useToastStore = defineStore('toast', {
  state: (): State => ({
    toasts: new Map(),
    meetings: [],
    messages: [],
    phones: [],
  }),
  getters: {
    toastLength: (state): number => state.toasts.size,
  },
  actions: {
    removeFirstToast() {
      const iterator = this.toasts.keys();
      const firstElementId = iterator.next().value;

      if (firstElementId) {
        this.toasts.delete(firstElementId);
      }
    },

    removeToastById(id: string) {
      this.toasts.delete(id);
    },

    newMessage(message: Message) {
      if (this.toasts.size === maximumNumberOfToasts) {
        this.removeFirstToast();
      }
      this.toasts.set(message.id, message);
    },

    newMeeting(meeting: Meeting) {
      if (this.toasts.size === maximumNumberOfToasts) {
        this.removeFirstToast();
      }
      this.toasts.set(meeting.id, meeting);
    },

    newPhone(phone: Phone) {
      if (this.toasts.size === maximumNumberOfToasts) {
        this.removeFirstToast();
      }
      this.toasts.set(phone.id, phone);
    },
  },
});
