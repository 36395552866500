import { defineStore } from 'pinia';
import type { AuthStore } from '@/types/auth';

export const useAuthStore = defineStore('auth', {
  state: (): AuthStore => ({
    isAuthenticated: false,
    sessionLifeTime: null,
  }),
  actions: {
    isAuthenticatedTruthy() {
      this.isAuthenticated = true;
    },
    isAuthenticatedFalsy() {
      this.isAuthenticated = false;
    },
    setSessionLifeTime(sessionLifeTime: Date | null) {
      this.sessionLifeTime = sessionLifeTime;
    },
  },
  persist: true,
});
