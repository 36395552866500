import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createHead } from '@vueuse/head';
import { i18n } from '@/plugins/i18n';
import Jtsi from '@jitsi/vue-sdk';
import * as Sentry from '@sentry/vue';

import App from './App.vue';
import router from './router';

import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { registerScrollSpy } from 'vue3-scroll-spy';

import Multiselect from '@suadelabs/vue3-multiselect';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import 'vue-advanced-cropper/dist/style.css';

import VueTippy from 'vue-tippy';

const app = createApp(App);
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

registerScrollSpy(app as unknown as any, { offset: 118 });

const head = createHead();

app.component('MultiSelect', Multiselect);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
      },
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/ci-switch.2talk.com/,
    /^https:\/\/app.2talk.com/,
    /^https:\/\/ci-switch.sipcity.com.au/,
    /^https:\/\/app.sipcity.com.au/,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment:
    import.meta.env.MODE === 'production' ? 'production' : 'development',
  debug: import.meta.env.MODE !== 'production',
});

app.use(pinia);
app.use(head);
app.use(i18n);
app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(VueTippy);
app.use(Jtsi);

app.mount('#app');
