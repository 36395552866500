export function useArray<T>(arr: T[]) {
  function findIndexByValue<K extends keyof T, V>(key: K, value: V) {
    return arr.findIndex((a) => a[key] === value);
  }

  function findElementByValue<K extends keyof T, V>(key: K, value: V) {
    return arr.find((a) => a[key] === value);
  }

  function removeElementByIndex(index: number) {
    return arr.splice(index, 1);
  }

  function difference(arr2: T[]) {
    return arr.filter((x) => !arr2.includes(x));
  }

  return {
    findIndexByValue,
    findElementByValue,
    removeElementByIndex,
    difference,
  };
}
