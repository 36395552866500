import { useBreakpoints, breakpointsBootstrapV5 } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsBootstrapV5);

const isMobileSmall = breakpoints.smaller('sm');
const isMobile = breakpoints.between('sm', 'md');
const isTablet = breakpoints.between('md', 'lg');
const isLaptop = breakpoints.between('lg', 'xl');
const isDesktop = breakpoints.between('xl', 'xxl');
const isLargeDesktop = breakpoints['xxl'];

const largerThanSLg = breakpoints.greaterOrEqual('lg');
const lgAndSmaller = breakpoints.smallerOrEqual('lg');

export {
  isMobileSmall,
  isMobile,
  isTablet,
  isLaptop,
  isDesktop,
  isLargeDesktop,
  largerThanSLg,
  lgAndSmaller,
};
