import gsap from 'gsap';

export function removeMessageInEffect(id: string): Promise<void> {
  const messageElement = document.querySelector(`[data-message="${id}"]`);
  return new Promise((resolve) => {
    if (!messageElement) return resolve();
    gsap.to(messageElement, {
      autoAlpha: 0,
      height: 0,
      overflow: 'hidden',
      callback: () => {
        setTimeout(() => {
          resolve(messageElement?.remove());
        }, 400);
      },
    });
  });
}
