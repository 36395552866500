import axios, { AxiosError } from 'axios';
import { EndpointApi } from '@/enums';
import type {
  ApiDataException,
  NumberResponseData,
  SwitchCustomer,
} from '@/types';
import { useAccountStore, useUserStore } from '@/stores';
import { syncUserData, updateUserStore } from '@/composables';
import { phoneMasked } from '@/utils';

export async function useSwitchCustomer(accountCode: string) {
  try {
    const userStore = useUserStore();

    const response = await axios.post<SwitchCustomer>(
      EndpointApi.SwitchCustomer,
      { account_code: accountCode },
    );
    const data = response.data;

    userStore.setCurrentAccount({
      customer: data.current_customer.Customer,
      spCode: data.current_customer.SP_Code,
      accountCode: data.current_customer.Account_Code,
    });

    await updateUserStore(data);
    await syncUserData();
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useAllNumbers() {
  const accountStore = useAccountStore();
  accountStore.numbers = [];
  try {
    const response = await axios.get<NumberResponseData>(EndpointApi.AllNumber);
    const data = response.data.numbers;
    for (const number of data) {
      accountStore.setNumber({
        name: number.CallerName,
        number: phoneMasked(number.Number),
        password: number.Password,
        status: number.Status,
        accountCode: number.AccountCode,
        hostname: number.Hostname,
        numbersUnmasked: number.Number,
      });
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
