import axios from 'axios';
import type { AxiosError } from 'axios';
import { EndpointApi } from '@/enums';
import type {
  ApiData,
  ApiDataException,
  TeamsResponseData,
  TeamsNumbersResponseData,
  Invite,
} from '@/types';
import { useUserStore } from '@/stores';
import type { InviteUserApiResponse, Teams } from '@/types';

export async function getTeams() {
  const userStore = useUserStore();
  try {
    const response = await axios.get<TeamsResponseData>(EndpointApi.GetTeams);
    const data = response.data.teams;
    userStore.teams = [];

    for (const team of data) {
      const assignedNumbers: { number: string }[] = [];
      for (const item of team.assigned_numbers) {
        assignedNumbers.push({ number: item.number });
      }
      userStore.setTeams({
        userId: team.user_id,
        email: team.email,
        userName: team.user_name,
        type: team.user_type,
        assignedNumbers,
      });
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function getTeamsNumbers() {
  const userStore = useUserStore();
  try {
    const response = await axios.get<TeamsNumbersResponseData>(
      EndpointApi.GetTeamsNumbers,
    );
    const data = response.data.numbers;
    userStore.teamsNumbers = [];

    for (const teamNumber of data) {
      const users: { userName: string; userId: number }[] = [];
      for (const item of teamNumber.users) {
        users.push({ userName: item.user_name, userId: item.user_id });
      }

      userStore.setTeamsNumbers({
        number: teamNumber.ServiceName,
        users,
      });
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function addTeamsNumber(numbers: string[]) {
  const userStore = useUserStore();
  try {
    const response = await axios.put<ApiData>(
      `${EndpointApi.EditUser}/${userStore.user.userId}/edit`,
      {
        number: numbers,
      },
    );
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function editUserTeam(user: Teams) {
  return await axios.put<InviteUserApiResponse>(
    `${EndpointApi.EditUser}/${user.userId}/edit-user`,
    {
      name: user.userName,
      email: user.email,
      number: user.assignedNumbers.map((number) => number.number),
    },
  );
}

export async function deleteUser(user: Teams) {
  return await axios.delete<ApiData>(
    `${EndpointApi.EditUser}/${user.userId}/delete-user`,
  );
}

export async function deleteNumber(userId: number, number: string | string[]) {
  return await axios.put<ApiData>(`${EndpointApi.EditUser}/${userId}/delete`, {
    number: number,
  });
}

export async function notificateUserToJoin(user: Invite) {
  const response = await axios.post<ApiData>(
    EndpointApi.NotifyUserJoinWorkspace,
    {
      email: user.email,
    },
  );

  return response;
}
