import { ref } from 'vue';
import { useIndexesStore } from '@/stores';

export function useImage() {
  const indexesStore = useIndexesStore();

  const imgBase64 = ref<undefined | string>('');

  async function toDataURL(src: string, outputFormat = 'image/png') {
    return new Promise<string>((resolve) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = src;

      img.onload = function () {
        const self = this as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = self.naturalHeight;
        canvas.width = self.naturalWidth;

        const x = 0;
        const y = 0;
        if (context) {
          context.drawImage(self, x, y);
        }
        resolve(canvas.toDataURL(outputFormat));
      };
    });
  }

  async function setIconAvatar(srcUser: number) {
    const SIZE_AVATAR = 80;
    if (Object.entries(indexesStore.avatars[srcUser]).length > 0) {
      imgBase64.value = await toDataURL(
        indexesStore.avatars[srcUser][SIZE_AVATAR],
      );
      return;
    }

    imgBase64.value = undefined;
  }

  return {
    imgBase64,
    setIconAvatar,
  };
}
