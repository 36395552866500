export enum Time {
  Millisecond = 1,
  Second = Millisecond * 1000,
  Minute = Second * 60,
  Hour = Minute * 60,
  Day = Hour * 24,
}

export enum TypingEnum {
  Limit = 5 * Time.Second,
}
