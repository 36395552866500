import axios, { AxiosError } from 'axios';
import type {
  ApiDataException,
  DeleteFavoriteResponseData,
  SetFavoriteResponseData,
  GetFavoriteResponseData,
} from '@/types';
import { EndpointApi } from '@/enums';
import { useSmsMessageStore, useUserStore } from '@/stores';

export async function useGetFavorite() {
  const userStore = useUserStore();
  const smsMessageStore = useSmsMessageStore();

  smsMessageStore.emptyUnknownFavorite();
  try {
    const response = await axios.get<GetFavoriteResponseData>(
      EndpointApi.GetFavorite,
    );
    const data = response.data.favorites;

    for (const favorite of data) {
      switch (favorite.type) {
        case 'group':
          userStore.setGroupFavorite(parseInt(favorite.favorite_id, 10));
          break;
        case 'contact':
          userStore.setImportedContactsFavorite(
            parseInt(favorite.favorite_id, 10),
          );
          break;
        case 'phone':
          smsMessageStore.setUnknownFavoriteByNumber(favorite.favorite_id);
          break;
        default:
          userStore.setFavorite(favorite.favorite_id);
      }
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useSetFavorite(
  id: string,
  type: 'user' | 'contact' | 'group' | 'phone',
) {
  const userStore = useUserStore();
  const smsMessageStore = useSmsMessageStore();
  try {
    const response = await axios.post<SetFavoriteResponseData>(
      EndpointApi.SetFavorite,
      {
        favorite_id: id,
        type,
      },
    );
    switch (type) {
      case 'group':
        userStore.setGroupFavorite(parseInt(id, 10));
        break;
      case 'contact':
        userStore.setImportedContactsFavorite(parseInt(id, 10));
        break;
      case 'phone':
        smsMessageStore.setUnknownFavoriteByNumber(id);
        break;
      default:
        userStore.setFavorite(id);
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteFavorite(favoriteId: string) {
  const userStore = useUserStore();
  try {
    const response = await axios.delete<DeleteFavoriteResponseData>(
      `${EndpointApi.DeleteFavorite}/${favoriteId}`,
    );
    userStore.removeFavorite(favoriteId);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteFavoriteGroup(favoriteId: number) {
  const userStore = useUserStore();
  try {
    const response = await axios.delete<DeleteFavoriteResponseData>(
      `${EndpointApi.DeleteFavoriteGroup}/${favoriteId}`,
    );
    userStore.removeFavoriteGroup(favoriteId);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteFavoriteContact(favoriteId: string) {
  const userStore = useUserStore();
  try {
    const response = await axios.delete<DeleteFavoriteResponseData>(
      `${EndpointApi.DeleteFavoriteContact}/${favoriteId}`,
    );
    userStore.removeFavorite(favoriteId);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useDeleteFavoritePhone(favoriteId: string) {
  const smsMessageStore = useSmsMessageStore();
  try {
    const response = await axios.delete<DeleteFavoriteResponseData>(
      `${EndpointApi.DeleteFavoritePhone}/${favoriteId}`,
    );
    smsMessageStore.deleteUnknownFavoriteByNumber(favoriteId);
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
