import type { Locales } from '@/types';
import { getFlagAsset } from '@/utils';

interface CountryList {
  code: Locales;
  name: string;
}

export const countryList: CountryList[] = [
  { code: 'en_UK', name: 'English (UK)' },
  { code: 'en', name: 'English (US)' },
  { code: 'pt_BR', name: 'Portuguese' },
  { code: 'es', name: 'Spanish' },
];
