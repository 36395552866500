import { defineStore } from 'pinia';
import type {
  Directory,
  InviteUserApiResponse,
  OnlineList,
  Teams,
  UserStatusEnum,
} from '@/types';
import { computed, onMounted, ref } from 'vue';
import { useSocketConnectionStore } from '@/stores/socket-connection';
import { useUserStore } from '@/stores/user';
import { useIndexesStore } from '@/stores/indexes';

export const useStatusStore = defineStore('user-status', () => {
  const status = ref<OnlineList>({});

  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const indexStore = useIndexesStore();
  const ioClient = computed(() => socketConnectionStore.ioClient);

  function setStatusList(onlines: OnlineList) {
    status.value = onlines;
  }

  function updateStatus(status: UserStatusEnum = userStore.onlineStatus) {
    userStore.setOnlineStatus(status);

    ioClient.value?.emit(
      'set-user-status',
      status,
      userStore.currentAccount.accountCode,
      userStore.user.userId,
      (onlines: OnlineList) => {
        if (!onlines) {
          return;
        }

        setStatusList(onlines);
      },
    );
  }

  function userStatus() {
    setInterval(() => {
      updateStatus();
    }, 60000);
  }

  function reflectingUserChanges(
    user: InviteUserApiResponse | Teams,
    status: string,
  ) {
    ioClient.value?.emit(
      'user-update',
      userStore.currentAccount.accountCode,
      user,
      status,
    );
  }

  function startListeners() {
    ioClient.value?.on('group-user-changed', (onlines: OnlineList) => {
      setStatusList(onlines);
    });

    ioClient.value?.on(
      'user-just-updated',
      (data: InviteUserApiResponse | Teams, type: string) => {
        console.log('user Update', data, type);
        switch (type) {
          case 'add':
            userStore.addDirectory(data as InviteUserApiResponse);
            break;
          case 'update':
            // eslint-disable-next-line no-case-declarations
            const info = data as InviteUserApiResponse;
            // eslint-disable-next-line no-case-declarations
            const directoryInfo: Directory = {
              ...indexStore.directories[info.user.user_id],
              userId: info.user.user_id,
              accountCode: userStore.currentAccount.accountCode,
              userName: info.user.user_name,
              userType: info.user_type,
            };

            userStore.updateDirectory(directoryInfo);
            break;
          case 'delete':
            userStore.removeDirectory((data as Teams).userId.toString());
            break;
        }
      },
    );
  }

  onMounted(() => {
    userStatus();
    startListeners();
  });

  return {
    status,
    setStatusList,
    updateStatus,
    reflectingUserChanges,
    startListeners,
  };
});
