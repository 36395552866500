import { defineStore } from 'pinia';

type Os = 'Unknown OS' | 'Windows' | 'MacOS' | 'Linux';

interface Store {
  detectOS: Os;
}
export const useOsStore = defineStore('os', {
  state: (): Store => ({
    detectOS: 'Unknown OS',
  }),
  actions: {
    setDetectOS() {
      if (navigator.userAgent.indexOf('Win') != -1) this.detectOS = 'Windows';

      if (navigator.userAgent.indexOf('Mac') != -1) this.detectOS = 'MacOS';

      if (navigator.userAgent.indexOf('Linux') != -1) this.detectOS = 'Linux';
    },
  },
  persist: true,
});
