import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import {
  useUserStore,
  useSocketConnectionStore,
  useMessageStore,
  useIndexesStore,
} from '@/stores';
import type { UnknownSms } from '@/utils/db/types';
import { db, extractNumber, phoneMasked } from '@/utils';
import type { ImportedContacts, UnknownSmsMessage } from '@/types';
import { useRoute, useRouter } from 'vue-router';
import { RouteName } from '@/enums';
import { useToastStore } from '@/stores/toast';
import ContactIcon from '@/assets/images/icon-contacts.svg';
import { useContact } from '@/composables';

interface SendSms {
  srcNumber: string;
  dstNumber: string;
  content: string;
}

interface ErrorSendSms {
  error: {
    message: string;
  };
}

interface UnknownContactSmsResponse {
  number: string;
  qtd_messages_not_read: number;
  first_message_at: string;
  last_message_at: string;
}

interface UnknownContactSms {
  number: string;
  messagesNotRead: number;
  firstMessageDate: Date;
  lastMessageDate: Date;
}

interface DataResponseSms {
  id: string;
  content: string;
  src_number: string;
  src_user: number | null;
  dst_number: string;
  status: {
    status: string;
    date: string;
  }[];
  created_at: string;
}

interface DataResponse {
  id: string;
  content: string;
  src_number: string;
  src_user: number | null;
  dst_number: string;
  status: string;
  created_at: string;
}

interface SyncUnknownInitialResponse {
  data: DataResponse[];
}

interface SyncGetUnknownBatch {
  date: string;
  numbers: string[];
}

interface UnknownFavorite {
  number: string;
}

const minimumMessageLimit = 50;
const firstElementIndex = 0;

export const useSmsMessageStore = defineStore('sms', () => {
  const socketStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const messageStore = useMessageStore();
  const indexesStore = useIndexesStore();

  const router = useRouter();
  const toastStore = useToastStore();
  const { addRecentContact } = useContact();

  const newMessage = ref(false);
  const chatIsActive = ref(true);
  const hasErrorEnablingSms = ref(false);
  const hasErrorFailedSms = ref(false);
  const number = ref('');
  const contactUnknownSms = ref<UnknownContactSms[]>([]);
  const unknownSmsMessages = ref<UnknownSmsMessage[]>([]);
  const unknownFavorite = ref<UnknownFavorite[]>([]);
  const route = useRoute();

  const socket = computed(() => socketStore.ioClient);

  const unreadUnknownSmsByNumber = computed(() => {
    return (number: string) => {
      const contactUnknown = contactUnknownSms.value.find(
        (contact) => contact.number === number,
      );
      return contactUnknown ? contactUnknown.messagesNotRead : 0;
    };
  });

  function emptyUnknownFavorite() {
    unknownFavorite.value = [];
  }

  function setUnknownFavoriteByNumber(number: string) {
    const phones = getContactPhone();
    if (!phones.includes(phoneMasked(extractNumber(number)))) {
      unknownFavorite.value.push({ number });
    }
  }

  function deleteUnknownFavoriteByNumber(number: string) {
    const index = unknownFavorite.value.findIndex(
      (favorite) => favorite.number === number,
    );

    if (index !== -1) {
      unknownFavorite.value.splice(index, 1);
    }
  }

  const unknownNumberIsFavorite = computed(() => {
    return (number: string) => {
      return !!unknownFavorite.value.find(
        (favorite) => favorite.number === number,
      );
    };
  });

  function chatIsActiveIsTruthy() {
    chatIsActive.value = true;
  }

  function chatIsActiveIsFalsy() {
    chatIsActive.value = false;
  }

  function getContactUnknownSmsByNumber(number: string) {
    return contactUnknownSms.value.find(
      (contact) => extractNumber(contact.number) === extractNumber(number),
    );
  }

  function sendSms({ srcNumber, dstNumber, content }: SendSms) {
    socket.value?.emit(
      'post-sms',
      {
        src_user: userStore.user.userId,
        src_number: extractNumber(phoneMasked(srcNumber)),
        dst_number: extractNumber(phoneMasked(dstNumber)),
        account_code: userStore.currentAccount.accountCode,
        content,
      },
      async (response: any) => {
        listUnknownContactsSms();
        const message = 'Unauthorized action.';

        if ((response as ErrorSendSms).error) {
          if (Object.entries(response.error).length === 0) {
            hasErrorFailedSms.value = true;
            return;
          }

          if ((response as ErrorSendSms).error.message.includes(message)) {
            number.value = srcNumber;
            hasErrorEnablingSms.value = true;
            return;
          }
          hasErrorFailedSms.value = true;
          return;
        }

        if (response.type === 'sms-unknown') {
          const smsMessage = response as DataResponseSms;
          await db.unknown_sms.put({
            id: smsMessage.id,
            content: smsMessage.content,
            src_number: smsMessage.src_number,
            dst_number: smsMessage.dst_number,
            status: smsMessage.status,
            src_user: smsMessage.src_user,
            created_at: smsMessage.created_at,
          });
          const unknownSms = await getSmsMessagesDbByNumber([
            smsMessage.dst_number,
          ]);
          const messages = await getUnknownSmsMessages(unknownSms);
          unknownSmsMessages.value = [...messages];
          newMessage.value = true;

          if (route.name === RouteName.Sms) {
            await router.push({
              name: RouteName.SendSms,
              params: { id: smsMessage.dst_number },
            });
          }

          const status = response.status;
          if (status.some((s: any) => s.status === 'failed')) {
            hasErrorFailedSms.value = true;
            return;
          }
        }

        if (response.type === 'sms') {
          messageStore.insertMessageFromSocket(response);

          const status = JSON.parse(response.content).status;
          if (status.some((s: any) => s.status === 'failed')) {
            hasErrorFailedSms.value = true;
          }
        }
      },
    );
  }

  function getContactPhone() {
    return userStore.importedContacts.reduce((arr, c) => {
      if (!c.phones) {
        return arr;
      }
      for (const phone of c.phones)
        if (phone.number) {
          arr.push(phoneMasked(extractNumber(phone.number)));
        }
      return arr;
    }, [] as string[]);
  }

  function listUnknownContactsSms() {
    socket.value?.emit(
      'list-contacts-unknown',
      {
        user: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
      },
      (response: UnknownContactSmsResponse[]) => {
        contactUnknownSms.value = [];
        const phones = getContactPhone();

        for (const contact of response) {
          indexesStore.setUnreadUnknownSmsMessages(
            contact.number,
            contact.qtd_messages_not_read,
          );

          if (contact.qtd_messages_not_read > 0)
            addRecentContact(contact.number);

          if (!phones.includes(phoneMasked(extractNumber(contact.number)))) {
            contactUnknownSms.value.push({
              number: contact.number,
              messagesNotRead: contact.qtd_messages_not_read,
              firstMessageDate: new Date(contact.first_message_at),
              lastMessageDate: new Date(contact.last_message_at),
            });
          }
        }
      },
    );
  }

  async function changeMessagesUnknownSms(
    response: SyncUnknownInitialResponse,
    messages: UnknownSms[],
  ) {
    for await (const message of response.data) {
      messages.push({
        id: message.id,
        content: message.content,
        src_number: message.src_number,
        src_user: message.src_user,
        dst_number: message.dst_number,
        status: JSON.parse(message.status),
        created_at: message.created_at,
      });
    }
  }

  function syncUnknownInitial(numbers: string[]) {
    return new Promise((resolve) => {
      socket.value?.emit(
        'sync-numbers-initial',
        {
          user: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          numbers: numbers.map((number) => extractNumber(phoneMasked(number))),
        },
        async (response: SyncUnknownInitialResponse) => {
          const messages: UnknownSms[] = [];
          await changeMessagesUnknownSms(response, messages);
          await db.unknown_sms.bulkPut(messages);
          resolve(true);
        },
      );
    });
  }

  async function getSmsMessagesDbByNumber(numbers: string[]) {
    return db.unknown_sms
      .where('src_number')
      .anyOf(numbers)
      .or('dst_number')
      .anyOf(numbers)
      .sortBy('created_at');
  }

  async function getFirstMessageDateDateByNumber(numbers: string[]) {
    const smsMessages = await getSmsMessagesDbByNumber(numbers);
    return smsMessages[firstElementIndex].created_at;
  }

  async function setUnknownSmsMessages(messages: UnknownSms[]) {
    unknownSmsMessages.value = [];
    for await (const message of messages) {
      unknownSmsMessages.value.push({
        id: message.id,
        content: message.content,
        srcNumber: message.src_number,
        srcUser: message.src_user,
        dstNumber: message.dst_number,
        status: message.status,
        createdAt: message.created_at,
      });
    }
  }

  async function getUnknownSmsMessages(messages: UnknownSms[]) {
    const smsMessages: UnknownSmsMessage[] = [];
    for await (const message of messages) {
      smsMessages.push({
        id: message.id,
        content: message.content,
        srcNumber: message.src_number,
        srcUser: message.src_user,
        dstNumber: message.dst_number,
        status: message.status,
        createdAt: message.created_at,
      });
    }

    return smsMessages;
  }

  async function getSmsMessages(numbers: string[]) {
    let messages = await getSmsMessagesDbByNumber(numbers);

    if (messages.length >= minimumMessageLimit) {
      await setUnknownSmsMessages(messages);
      return;
    }
    await syncUnknownInitial(numbers);
    messages = await getSmsMessagesDbByNumber(numbers);
    await setUnknownSmsMessages(messages);
  }

  async function getSmsMessagesWhenEditContact(numbers: string[]) {
    await syncUnknownInitial(numbers);
    const messages = await getSmsMessagesDbByNumber(numbers);
    await setUnknownSmsMessages(messages);
    newMessage.value = true;
  }

  function syncGetUnknownBatch({ date, numbers }: SyncGetUnknownBatch) {
    return new Promise((resolve) => {
      socket.value?.emit(
        'sync-numbers-batch',
        {
          user: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          numbers: numbers.map((number) => extractNumber(phoneMasked(number))),
          data_ref: date,
          operation: 'oldest',
        },
        async (response: SyncUnknownInitialResponse) => {
          const batchMessages: UnknownSms[] = [];
          await changeMessagesUnknownSms(response, batchMessages);
          await db.unknown_sms.bulkPut(batchMessages);
          const unknownSms = await getSmsMessagesDbByNumber(numbers);
          const messages = await getUnknownSmsMessages(unknownSms);
          unknownSmsMessages.value = [...messages];

          if (response.data.length > 0) {
            newMessage.value = true;
          }

          resolve(true);
        },
      );
    });
  }

  function checkSmsUnknownRead(number: string) {
    socket.value?.emit('check-sms-unknown-read', {
      user: userStore.user.userId,
      account_code: userStore.currentAccount.accountCode,
      contact_number: number,
    });

    indexesStore.clearUnreadUnknownSmsMessages(number);
  }

  function getUrlToastMessage(number: string, contact?: ImportedContacts) {
    if (contact) {
      if (contact.isDirectory) {
        return router.resolve({
          name: RouteName.Directory,
          params: { id: contact.directory.id },
        });
      }

      return router.resolve({
        name: RouteName.Contact,
        params: { id: contact.id },
      });
    }

    return router.resolve({
      name: RouteName.SendSms,
      params: { id: number },
    });
  }

  function newSmsUnknown() {
    socket.value?.on('new-sms-unknown', async (response: DataResponseSms) => {
      await db.unknown_sms.put({
        id: response.id,
        content: response.content,
        src_number: response.src_number,
        dst_number: response.dst_number,
        status: response.status,
        src_user: response.src_user,
        created_at: response.created_at,
      });

      const number = String(route.params.id);

      if (route.name === RouteName.SendSms && response.src_number === number) {
        checkSmsUnknownRead(response.src_number);
        const unknownSms = await getSmsMessagesDbByNumber([number]);
        const messages = await getUnknownSmsMessages(unknownSms);
        unknownSmsMessages.value = [...messages];
      } else {
        listUnknownContactsSms();

        if (response.src_user !== userStore.user.userId) {
          const contact = userStore.getImportedContactByNumber(
            response.src_number,
          );

          const title = contact
            ? `${contact.firstName} ${contact.lastName}`.trim()
            : phoneMasked(response.src_number);

          toastStore.newMessage({
            id: window.crypto.randomUUID(),
            title,
            message: response.content,
            type: 'directory',
            url: getUrlToastMessage(response.src_number, contact),
            avatarUrl: ContactIcon,
            variant: 'message',
          });
        }
      }

      addRecentContact(response.src_number);
      indexesStore.increaseUnreadUnknownSmsMessages(response.src_number, 1);

      newMessage.value = true;
    });
  }

  function updateSmsUnknown() {
    socket.value?.on('update-sms-unknown', async (response) => {
      await db.unknown_sms.put({
        id: response.id,
        content: response.content,
        src_number: response.src_number,
        dst_number: response.dst_number,
        status: response.status,
        src_user: response.src_user,
        created_at: response.created_at,
      });

      indexesStore.increaseUnreadUnknownSmsMessages(response.src_number, 1);

      const number = String(route.params.id);
      if (route.name === RouteName.SendSms && response.src_number === number) {
        const unknownSms = await getSmsMessagesDbByNumber([number]);
        const messages = await getUnknownSmsMessages(unknownSms);
        unknownSmsMessages.value = [...messages];
        newMessage.value = true;
      }
    });
  }

  function listenersSmsMessage() {
    newSmsUnknown();
    updateSmsUnknown();
  }

  return {
    chatIsActive,
    hasErrorEnablingSms,
    hasErrorFailedSms,
    contactUnknownSms,
    unknownSmsMessages,
    number,
    unknownFavorite,
    unknownNumberIsFavorite,
    unreadUnknownSmsByNumber,
    newMessage,
    emptyUnknownFavorite,
    setUnknownFavoriteByNumber,
    deleteUnknownFavoriteByNumber,
    chatIsActiveIsTruthy,
    chatIsActiveIsFalsy,
    sendSms,
    listUnknownContactsSms,
    getSmsMessages,
    syncGetUnknownBatch,
    getFirstMessageDateDateByNumber,
    getContactUnknownSmsByNumber,
    checkSmsUnknownRead,
    listenersSmsMessage,
    getSmsMessagesWhenEditContact,
  };
});
