export enum EndpointApi {
  Login = 'api/jwt/login',
  Me = 'api/jwt/user-info',
  RefreshToken = 'api/jwt/refreshToken',
  SwitchCustomer = 'api/jwt/set-customer',
  AllNumber = 'api/jwt/numbers/all',
  Setting = 'api/jwt/setting',
  Profile = 'api/jwt/profile',
  Avatar = 'api/jwt/avatar',
  Invite = 'api/jwt/invite',
  ResetPassword = 'api/jwt/password/reset',
  CompleteProfile = 'api/jwt/complete-profile',
  ForgotPassword = 'api/jwt/password/forgot',
  Directory = 'api/jwt/directory',
  SetFavorite = 'api/jwt/favorite/set',
  GetFavorite = 'api/jwt/favorite/get',
  DeleteFavorite = 'api/jwt/favorite/delete',
  DeleteFavoriteGroup = 'api/jwt/favorite/group/delete',
  DeleteFavoriteContact = 'api/jwt/favorite/contact/delete',
  DeleteFavoritePhone = 'api/jwt/favorite/phone/delete',
  GetTeams = 'api/jwt/teams',
  GetTeamsNumbers = 'api/jwt/teams/numbers',
  EditUser = 'api/jwt/teams/user',
  Search = 'api/jwt/search',
  Numbers = 'api/jwt/numbers',
  GetVoiceMail = 'api/jwt/numbers/voicemails',
  Logout = 'api/jwt/logout',
  NotifyUserJoinWorkspace = 'api/jwt/notify-join-workspace',
  GetGroups = 'api/jwt/groups/my',
  NewGroup = 'api/jwt/groups/store',
  Group = 'api/jwt/groups',
  NewImportedContacts = 'api/jwt/contacts/import',
  GetImportedContacts = 'api/jwt/contacts/my',
  DeleteImportedContacts = 'api/jwt/contacts/delete-contacts-provider',
  StoreContact = 'api/jwt/contacts/store',
  Contact = 'api/jwt/contacts',
  PutFile = 'put-file',
  PutGroupFile = 'group',
  DownloadFile = 'file',
  Settings = 'settings',
  DownloadCall = 'api/jwt/numbers/download-recording',
  RedirectToArena = 'api/jwt/redirect-to-arena',
  LoginWithWithArenaJWT = 'api/jwt/login-with-arena-jwt'
}
