import { defineStore } from 'pinia';
import type { Recent, TypeRecent } from '@/types';
import { type Favorites, useArray } from '@/composables';
import { extractNumber, phoneMasked } from '@/utils';
import { useUserStore } from '@/stores/user';

interface State {
  allRecent: Record<string, Recent[]>;
}
export const useRecentStore = defineStore('recent', {
  state: (): State => ({
    allRecent: {},
  }),
  getters: {
    recent: (state) => {
      const userStore = useUserStore();
      const { accountCode } = userStore.currentAccount;
      const { userId } = userStore.user;

      return state.allRecent[`${userId}-${accountCode}`]
        ? state.allRecent[`${userId}-${accountCode}`]
        : (state.allRecent[`${userId}-${accountCode}`] = []);
    },
  },
  actions: {
    findIndexWithTypeAndId(type: TypeRecent, id: string) {
      return this.recent.findIndex(
        (r: Recent) =>
          r.type === type &&
          phoneMasked(extractNumber(String(r.id))) ===
            phoneMasked(extractNumber(String(id))),
      );
    },

    removeRecentWithTypeAndId(type: TypeRecent, id: string) {
      const { removeElementByIndex } = useArray(this.recent);
      const index = this.findIndexWithTypeAndId(type, id);

      if (index !== -1) {
        removeElementByIndex(index);
      }
    },

    renameRecentByIndex(index: number, name: string) {
      if (index !== -1) {
        this.recent[index].name = name;
      }
    },

    updateRecentWhenFavoritesChange(favorites: Favorites[]) {
      const userStore = useUserStore();
      const { accountCode } = userStore.currentAccount;
      const { userId } = userStore.user;

      this.allRecent[`${userId}-${accountCode}`] = this.allRecent[
        `${userId}-${accountCode}`
      ].filter((recentItem) => {
        const favoriteItem = favorites.find(
          (favItem) =>
            favItem.id === recentItem.id && favItem.type === recentItem.type,
        );
        return !favoriteItem;
      });
    },

    updateRecentWhenUpdateUser() {
      const userStore = useUserStore();

      for (const recent of this.recent) {
        if (recent.type === 'directory') {
          const directoryIsActive = userStore.getUserIsActive(
            Number(recent.id),
          );
          if (directoryIsActive) {
            this.removeRecentWithTypeAndId('directory', String(recent.id));
          }
        }
      }
    },

    clearAllRecent() {
      this.allRecent = {};
    },
  },
  persist: true,
});
