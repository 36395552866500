import { defineStore } from 'pinia';
import type { Account, AccountNumber, AccountStore } from '@/types';

export const useAccountStore = defineStore('account', {
  state: (): AccountStore => ({
    numbers: [],
    accounts: [],
  }),
  actions: {
    setAccounts(account: Account) {
      this.accounts.push(account);
    },
    setNumber(number: AccountNumber) {
      this.numbers.push(number);
    },
  },
});
