import axios from 'axios';
import { useUserStore } from '@/stores';

export function axiosDefault() {
  const userStore = useUserStore();
  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = import.meta.env.VITE_BACKEND_DOMAIN;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common['Accept'] = 'application/json';

  if (userStore.user.authToken) {
    axios.defaults.headers.common['Authorization'] =
      `Bearer ${userStore.user.authToken}`;
  }
}
