import parsePhoneNumber from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js';

export function extractNumber(value: string) {
  const number = value.replace(/\D/g, '').replace(/^0+/, '');
  return number ? number : value;
}

export function isNumber(str: string) {
  return /^\d+$/.test(str);
}

export function getPhone(value: string) {
  return value.split(/[:@]/)[1];
}

export function phoneMasked(value: string) {
  if (!value) return value;

  const country = import.meta.env.VITE_COUNTRY as CountryCode;

  const numberStartWithPlus = value.startsWith('+');
  let phoneNumber = parsePhoneNumber(value, country);
  if (!phoneNumber?.isValid()) {
    if (numberStartWithPlus) phoneNumber = parsePhoneNumber(value, country);
    else phoneNumber = parsePhoneNumber(`+${value}`, country);
  }
  return phoneNumber?.formatInternational() ?? value;
}

export function isFirstCharacterPlusAndSecondDigit(value: string) {
  return value.length >= 2 && value[0] === '+' && !isNaN(+value[1]);
}
