import { computed, ref, watch } from 'vue';
import { useElementSize } from '@vueuse/core';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores';
import type { Directory, PhoneContact } from '@/types';
import { RouteName } from '@/enums';
import { useApp } from '@/composables/use-app';

export function useChat() {
  const route = useRoute();
  const userStore = useUserStore();
  const { isUS, startAustralianMobileNumber } = useApp();

  const panelRef = ref<HTMLDivElement>();
  const panelWidth = ref(0);
  const { width } = useElementSize(panelRef);
  const directory = ref<Directory>();

  const number = computed(() => {
    const queryNumber = route.query.number;
    return queryNumber ? String(queryNumber) : null;
  });

  const routeId = computed(() => route.params.id as string);
  const contact = computed(() => {
    if (route.name === RouteName.Contact) {
      return userStore.getImportedContactsById(parseInt(routeId.value, 10));
    }

    return userStore.getImportedContactsByDirectoryId(
      parseInt(routeId.value, 10),
    );
  });

  const editor = ref('<p></p>');
  const loadingMessages = ref(false);

  function setDirectory() {
    const id = String(route.params.id);

    if (id) {
      directory.value = userStore.directory(id);
    }
  }

  const numbersFrom = computed(() => {
    if (isUS.value) {
      return userStore.myNumbers;
    }

    return userStore.myNumbers.filter((number) =>
      number.numbersUnmasked.startsWith(startAustralianMobileNumber),
    );
  });

  function getNumbers(phones: PhoneContact[]) {
    const routeName = String(route.name);
    if (routeName === RouteName.Contact) {
      return (
        phones
          ?.filter((phone) => phone.number)
          ?.map((phone) => phone.number.trim()) ?? []
      );
    }

    if (routeName !== RouteName.Directory && !directory.value) return [];

    const team = userStore.getImportedContactsByDirectoryId(
      directory.value?.userId as number,
    );

    return (
      team?.phones
        .filter((number) => {
          if (isUS.value) {
            return true;
          }

          return number.number.startsWith(startAustralianMobileNumber);
        })
        .map((number) => number.number) ?? []
    );
  }

  const numbersTo = computed(() => {
    setDirectory();

    return getNumbers(contact.value?.phones ?? []);
  });

  const numbersToSms = computed(() => {
    setDirectory();

    return getNumbers(
      contact.value?.phones.filter((phone) => phone.canEdit) ?? [],
    );
  });

  const canISendSms = computed(() => numbersFrom.value.length > 0);

  function setHeightBaseChat(mainChat: HTMLElement, panel: HTMLElement) {
    const baseEditor = document.querySelector(
      '.base-editor-container',
    ) as HTMLElement;

    const heightMainPanel = mainChat.clientHeight;
    const heightBaseFooter = baseEditor.clientHeight + 72;
    const heightHeaderChat = 60;

    const heigthChat = heightMainPanel - heightBaseFooter - heightHeaderChat;
    panel.style.gridTemplateRows = `60px ${heigthChat}px ${heightBaseFooter}px`;
  }

  function resize() {
    const resizeObserver = new ResizeObserver(function () {
      const mainPanel = document.querySelector(
        '#chat-container',
      ) as HTMLElement;
      const panel = document.querySelector('.panel') as HTMLElement;
      setHeightBaseChat(mainPanel, panel);
    });

    const baseEditor = document.querySelector(
      '.base-editor-container',
    ) as HTMLElement;

    if (baseEditor) {
      resizeObserver.observe(baseEditor);
      resizeObserver.observe(document.body);
    } else {
      resize();
      return;
    }
  }

  function resizeSms() {
    const resizeObserver = new ResizeObserver(function () {
      const mainPanel = document.querySelector('#main-panel') as HTMLElement;
      const panel = document.querySelector('.sms-main') as HTMLElement;
      setHeightBaseChat(mainPanel, panel);
    });

    const baseEditor = document.querySelector(
      '.base-editor-container',
    ) as HTMLElement;

    if (baseEditor) {
      resizeObserver.observe(baseEditor);
      resizeObserver.observe(document.body);
    } else {
      resizeSms();
      return;
    }
  }

  watch(width, (value) => (panelWidth.value = value));

  return {
    panelRef,
    panelWidth,
    routeId,
    contact,
    loadingMessages,
    editor,
    numbersFrom,
    numbersTo,
    numbersToSms,
    number,
    canISendSms,
    resize,
    resizeSms,
  };
}
