export enum RoutePath {
  Home = '',
  Login = '/login',
  Chat = '/chat/:id',
  Activity = '/activity',
  Directory = '/directory/:id',
  Contact = '/contact/:id',
  Group = '/group/:id',
  ResetPassword = '/reset-password',
  CreateUser = '/complete-profile',
  Search = '/search',
  Conference = '/conference',
  Sms = '/sms',
  SendSms = '/sms/:id',
  ContactList = '/contact',
  LoginFromArenaJWT = '/login-arena/:jwt',
  NotFound = '/:pathMatch(.*)*'
}

export enum RouteName {
  Home = 'home',
  Login = 'login',
  Chat = 'chat',
  Activity = 'activity',
  Directory = 'directory',
  Contact = 'contact',
  Group = 'group',
  ResetPassword = 'reset-password',
  CreateUser = 'complete-profile',
  Search = 'search',
  Conference = 'conference',
  Sms = 'sms',
  SendSms = 'send-sms',
  ContactList = 'contact-list',
  LoginFromArenaJWT = 'login-arena',
  NotFound = 'not-found',
}
