import { computed } from 'vue';
import { useUserStore } from '@/stores';

export function useApp() {
  const country = 'US';
  const userStore = useUserStore();

  const startAustralianMobileNumber = '614';

  const isUS = computed(() => {
    return import.meta.env.VITE_COUNTRY === country;
  });

  const isAustralianMobileNumber = computed(() => {
    return userStore.myNumbers.some((number) =>
      number.numbersUnmasked.startsWith(startAustralianMobileNumber),
    );
  });

  const smsButtonIsDisabled = computed(() => {
    if (isUS.value) return false;

    return !isAustralianMobileNumber.value;
  });

  return {
    isUS,
    smsButtonIsDisabled,
    startAustralianMobileNumber,
  };
}
