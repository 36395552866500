import { useRecentStore, useUserStore } from '@/stores';
import { RouteName } from '@/enums';
import { db } from '@/utils';
import type { ContactDb } from '@/utils/db/types';
import type {
  SuccessSetImportedContactsResponseData,
  SuccessSetImportedDirectoryContactsResponseData,
} from '@/types';

export function useContact() {
  const userStore = useUserStore();
  const recentStore = useRecentStore();

  function addRecentDirectory(id: string) {
    const directory = userStore.directory(id);

    if (directory?.favorite) return;

    const contact = userStore.getImportedContactsByDirectoryId(Number(id));

    if (contact) {
      recentStore.removeRecentWithTypeAndId('directory', id);

      recentStore.recent.unshift({
        id: Number(id),
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'directory',
        routeName: RouteName.Directory,
      });
    }
  }

  function addRecentContact(number: string) {
    const contact = userStore.getImportedContactByNumber(number);

    if (contact) {
      if (contact.isDirectory) {
        addRecentDirectory(String(contact.directory.id));
        return;
      }

      if (contact.favorite) return;

      recentStore.removeRecentWithTypeAndId('contact', contact.id.toString(10));

      recentStore.recent.unshift({
        id: contact.id,
        name: `${contact.firstName} ${contact.lastName}`.trim(),
        type: 'contact',
        routeName: RouteName.Contact,
      });
    }
  }

  async function getContactDb() {
    const hash = `${userStore.user.userId}+${userStore.currentAccount.accountCode}`;

    return db.contacts.where('hash').equals(hash).toArray();
  }

  async function saveOrUpdateContactDb(contacts: ContactDb[]) {
    return db.contacts.bulkPut(contacts);
  }

  async function setContacts() {
    const contacts = await getContactDb();

    userStore.importedContacts = [];

    for (const contact of contacts) {
      if (Object.prototype.hasOwnProperty.call(contact, 'type')) {
        userStore.setImportedContacts(
          contact as SuccessSetImportedContactsResponseData,
        );
      }

      if (Object.prototype.hasOwnProperty.call(contact, 'user_name')) {
        userStore.setImportedDirectoryContacts(
          contact as SuccessSetImportedDirectoryContactsResponseData,
          String(userStore.user.userId),
        );
      }
    }

    userStore.setSourceEmailProvider();
    userStore.sortImportedContactsByNameAsc();
  }

  async function removeContactDbByProvider(provider: string) {
    return db.contacts.where('provider').equals(provider).delete();
  }

  async function removeContactById(id: number) {
    return db.contacts.delete(id);
  }

  return {
    addRecentDirectory,
    addRecentContact,
    setContacts,
    saveOrUpdateContactDb,
    removeContactDbByProvider,
    removeContactById,
  };
}
