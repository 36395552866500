const numberSettingsResponseData = [
  { pref_name: 'enable', pref_group: 'active', name: 'active' },
  { pref_name: 'enable', pref_group: 'dnd', name: 'dnd' },
  { pref_name: 'dnd', pref_group: 'dnd', name: 'dndTime' },
];

export function getNameNumberSettings(prefName: string, prefGroup: string) {
  const index = numberSettingsResponseData.findIndex(
    (element) =>
      element.pref_name === prefName && element.pref_group === prefGroup,
  );

  if (index !== -1) return numberSettingsResponseData[index].name;
  return null;
}
