import { computed, onMounted, ref } from 'vue';
import { db, removeMessageInEffect } from '@/utils';
import { defineStore } from 'pinia';
import { useSocketConnectionStore } from '@/stores/socket-connection';
import { useUserStore } from '@/stores';
import type {
  ChatMessage,
  ChatMessageSocket,
  ResponseDataChatMessage,
  ResponseDataChatMessageNotRead,
  ResponseDataSearchMessages,
} from '@/utils/db/types';
import { useChatMessageVars } from './chat-message-vars';
import { useContact, useImage, useNotification } from '@/composables';
import { useRoute, useRouter } from 'vue-router';
import type { RouteLocationRaw } from 'vue-router';
import { RouteName } from '@/enums';
import { useI18n } from 'vue-i18n';
import { useToastStore, useIndexesStore } from '@/stores';
import type { Socket } from 'socket.io-client';
import type { CreatedAt } from '@/types';

export const useMessageStore = defineStore('chat-message', () => {
  const router = useRouter();

  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const messageVars = useChatMessageVars();
  const indexesStore = useIndexesStore();
  const ioClient = computed(() => socketConnectionStore.ioClient);
  const { t } = useI18n();
  const { imgBase64, setIconAvatar } = useImage();
  const toastStore = useToastStore();
  const route = useRoute();
  const firstMessage = ref<ChatMessageSocket | null>(null);
  const { addRecentDirectory } = useContact();

  function newMessage(dstUser: number, message: string) {
    ioClient.value?.emit(
      'post-message',
      {
        src_user: userStore.user.userId,
        dst_user: dstUser,
        account_code: userStore.currentAccount.accountCode,
        content: message,
        type: 'message',
      },
      (response: ChatMessageSocket) => {
        insertMessageFromSocket(response);
      },
    );
  }

  function getSyncInitial(user2: number) {
    return new Promise((resolve) => {
      ioClient.value?.emit(
        'sync-initial',
        {
          account_code: userStore.currentAccount.accountCode,
          user: userStore.user.userId,
          user2: user2,
        },
        async (response: ResponseDataChatMessage) => {
          const arrayOfMessagesDexie = [] as ChatMessage[];
          response.data.forEach((message) => {
            ioClient.value?.emit('message-received', message);
            arrayOfMessagesDexie.push({
              ...message,
              read: message.read ? 1 : 0,
            });
          });

          await db.chat_messages.bulkPut(arrayOfMessagesDexie);
          resolve(response);
        },
      );
    });
  }

  function insertMessagesFromSocket(
    dataMessageResponseSocket: ChatMessageSocket[],
  ) {
    dataMessageResponseSocket.forEach((message) => {
      insertMessageFromSocket(message);
    });
  }

  function insertMessageFromSocket(messageSocket: ChatMessageSocket) {
    const message = {
      ...messageSocket,
      read: messageSocket.read ? 1 : 0,
    } as ChatMessage;

    if (
      messageVars.current_chat.user === message.src_user ||
      messageVars.current_chat.user === message.dst_user
    ) {
      messageVars.addChatMessage(message);
    }
    db.chat_messages.put(message);
  }

  function getBatchOfMessages(
    userId: number,
    operation: string,
    createdAt: CreatedAt,
  ) {
    ioClient.value?.emit(
      'sync-get-batch',
      {
        account_code: userStore.currentAccount.accountCode,
        user: userStore.user.userId,
        user2: userId,
        operation: operation,
        data_ref: operation === 'oldest' ? createdAt.first : createdAt.last,
      },
      (response: ResponseDataChatMessage) => {
        insertMessagesFromSocket(response.data);
      },
    );
  }

  function getMessagesNotRead() {
    ioClient.value?.emit(
      'messages-not-read',
      {
        account_code: userStore.currentAccount.accountCode,
        user: userStore.user.userId,
        groups: userStore.getGroupsId,
      },
      (response: ResponseDataChatMessageNotRead) => {
        for (const message of response.data.messages) {
          indexesStore.setUnreadDirectoryMessages(
            message.user_id,
            message.qtd_messages_not_read,
          );

          if (message.qtd_messages_not_read > 0) {
            addRecentDirectory(String(message.user_id));
          }
        }

        for (const groupMessage of response.data.groupMessages) {
          indexesStore.setUnreadGroupMessages(
            groupMessage.group_id,
            groupMessage.qtd_messages_not_read,
          );
        }
      },
    );
  }

  async function messagesRead(userId: number) {
    const messagesUser = await db.chat_messages
      .where({
        src_user: userId,
        dst_user: userStore.user.userId,
        read: 0,
      })
      .toArray();

    ioClient.value?.emit('check-messages-read', {
      src_user: userId,
      dst_user: userStore.user.userId,
      account_code: userStore.currentAccount.accountCode,
    });
    messagesUser.forEach((message) => {
      db.chat_messages.update(message.id, { read: 1 });
    });
    indexesStore.clearUnreadDirectoryMessages(userId);
  }

  function searchMessages(query: string, data_begin = '') {
    return new Promise<ResponseDataSearchMessages>((resolve, reject) => {
      ioClient.value?.emit(
        'search-messages',
        {
          user_id: userStore.user.userId,
          account_code: userStore.currentAccount.accountCode,
          groups: userStore.getGroupsId,
          query,
          data_begin,
        },
        (response: ResponseDataSearchMessages) => {
          if (response.error) {
            reject(response.error);
          }

          resolve(response);
        },
      );
    });
  }

  function searchMessage(user2: number, dateRef: string | Date) {
    ioClient.value?.emit(
      'search-message',
      {
        user: userStore.user.userId,
        user2: user2,
        account_code: userStore.currentAccount.accountCode,
        data_ref: dateRef,
      },
      (response: ResponseDataChatMessage) => {
        const arrayOfMessagesDexie = [] as ChatMessage[];
        response.data.forEach((message) => {
          ioClient.value?.emit('message-received', message);
          arrayOfMessagesDexie.push({
            ...message,
            read: message.read ? 1 : 0,
          });
        });

        db.chat_messages.bulkPut(arrayOfMessagesDexie);
        messageVars.setChatMessages(arrayOfMessagesDexie);
      },
    );
  }

  function editMessage(dstUser: number, createdAt: Date, newMessage: string) {
    ioClient.value?.emit(
      'edit-message',
      {
        src_user: userStore.user.userId,
        dst_user: dstUser,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
        new_message: newMessage,
      },
      (message: ChatMessageSocket) => {
        insertMessageFromSocket(message);
      },
    );
  }

  async function removeMessageDatabase(id: string) {
    return db.chat_messages.where({ id }).delete();
  }

  function deleteMessage(dstUser: number, createdAt: Date) {
    ioClient.value?.emit(
      'delete-message',
      {
        src_user: userStore.user.userId,
        dst_user: dstUser,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
      },
      async (message: ChatMessageSocket) => {
        await removeMessageInEffect(message.id);
        setTimeout(() => {
          messageVars.removeChatMessage(message.id);
          removeMessageDatabase(message.id);
        }, 500);
      },
    );
  }

  async function getFirstMessageByUser(dstUser: number) {
    return new Promise((resolve) => {
      ioClient.value?.emit(
        'first-message',
        {
          user: userStore.user.userId,
          user2: dstUser,
          account_code: userStore.currentAccount.accountCode,
        },
        (message: ChatMessageSocket | null) => {
          resolve((firstMessage.value = message));
        },
      );
    });
  }

  function onReloadMessages() {
    const id = Number(route.params.id);

    ioClient.value?.on('reconnect', () => {
      if (route.name !== RouteName.Directory) return;

      getSyncInitial(id);
    });
  }

  function onListenersMessage() {
    ioClient.value?.on('new-message', async (message: ChatMessageSocket) => {
      ioClient.value?.emit('message-received', message);
      insertMessageFromSocket(message);
      addRecentDirectory(message.src_user.toString(10));

      const routerIdParameter =
        (router?.currentRoute?.value?.params?.id as string) ?? 0;

      if (
        indexesStore.scrollLock &&
        (router?.currentRoute?.value?.name === 'directory' ||
          router?.currentRoute?.value?.name === 'favorite') &&
        parseInt(routerIdParameter) === message.src_user
      ) {
        return;
      }

      await setIconAvatar(message.src_user);
      const userName = indexesStore.directories[message.src_user].userName;
      const title = userName
        ? t('new-message-chat', { user: userName })
        : 'New Message';
      const url: RouteLocationRaw = {
        name: RouteName.Directory,
        params: {
          id: message.src_user,
        },
        query: {
          messageId: message.id,
        },
      };
      const hasImage = message.content.startsWith('<p><img src="data:image');
      const content = hasImage ? 'Shared this image' : message.content;
      const body =
        new DOMParser().parseFromString(content, 'text/html').body
          .textContent ?? '';
      function getContent(messageType: ChatMessage['type']) {
        let content = '';
        switch (messageType) {
          case 'sms':
            content = JSON.parse(body).content;
            break;
          case 'attachment': {
            content = JSON.parse(body).message;
            content = content ? content : 'Sent an attachment';
            break;
          }
          default:
            content = body;
        }

        return content;
      }
      const messageContent = getContent(message.type);

      if (userStore.onlineStatus !== 'dnd') {
        useNotification({
          title,
          body: messageContent,
          url,
          router,
        });
        toastStore.newMessage({
          id: window.crypto.randomUUID(),
          title: userName,
          message: messageContent,
          type: 'directory',
          url,
          avatarUrl: imgBase64.value,
          variant: 'message',
        });
      }

      indexesStore.increaseDirectoryMessages(message.src_user, 1);
    });

    ioClient.value?.on('new-call', (message: ChatMessageSocket) => {
      insertMessageFromSocket(message);
      addRecentDirectory(message.src_user.toString(10));
    });

    ioClient.value?.on('update-call', (message: ChatMessageSocket) => {
      insertMessageFromSocket(message);
      addRecentDirectory(message.src_user.toString(10));
    });

    ioClient.value?.on('update-message', (message: ChatMessageSocket) => {
      insertMessageFromSocket(message);
      addRecentDirectory(message.src_user.toString(10));
    });

    onReloadMessages();
  }

  function onDeleteMessage(socket: Socket) {
    socket.on('delete-message', async (message: ChatMessageSocket) => {
      await removeMessageInEffect(message.id);

      setTimeout(async () => {
        messageVars.removeChatMessage(message.id);
        await removeMessageDatabase(message.id);
        const unreadMessages =
          indexesStore.unreadDirectoryMessages[message.src_user];
        indexesStore.unreadDirectoryMessages[message.src_user] =
          unreadMessages > 0 && !message.read ? unreadMessages - 1 : 0;
      }, 1000);
    });
  }

  onMounted(() => {
    onListenersMessage();
  });

  return {
    firstMessage,
    newMessage,
    getSyncInitial,
    getBatchOfMessages,
    messagesRead,
    onListenersMessage,
    getMessagesNotRead,
    insertMessageFromSocket,
    searchMessages,
    searchMessage,
    editMessage,
    deleteMessage,
    getFirstMessageByUser,
    onDeleteMessage,
  };
});
