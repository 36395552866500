import { defineStore } from 'pinia';
import type { Locales } from '@/types';
import { countryList } from '@/constants';
import { useI18n } from 'vue-i18n';

interface UseLanguageStore {
  locale: Locales;
}

export const useLanguageStore = defineStore('language', {
  state: (): UseLanguageStore => ({
    locale: '',
  }),
  actions: {
    changeLanguage(language: Locales) {
      this.locale = language;
    },
    setLanguage() {
      const { locale } = useI18n();
      if (this.locale === '') {
        const language = navigator.language.replace('-', '_');

        if (countryList.filter((l) => l.code === language).length > 0) {
          this.locale = language as Locales;
          locale.value = language;
        } else {
          this.locale = 'en';
          locale.value = this.locale;
        }
      } else {
        locale.value = this.locale;
      }
    },
  },
  persist: true,
});
