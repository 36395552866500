import { defineStore } from 'pinia';
import type { Socket } from 'socket.io-client';
import { ref } from 'vue';

interface ResponseListPresenceConference {
  user: number;
}

interface ResponsePresenceConference {
  user: number;
  status: Status;
}

type Status = 'on' | 'left';

export const usePresenceForMeetingStore = defineStore(
  'presence-for-meeting',
  () => {
    const presenceForVideo = ref<Record<number, Status>>({});

    function listPresenceConference(socket: Socket, accountCode: string) {
      socket.emit(
        'list-presence-conference',
        accountCode,
        (response: ResponseListPresenceConference[]) => {
          for (const { user } of response) {
            presenceForVideo.value[user] = 'on';
          }
        },
      );
    }

    function presenceConference(socket: Socket, accountCode: string) {
      socket.on(
        'presence-conference',
        (response: ResponsePresenceConference) => {
          presenceForVideo.value[response.user] = response.status;

          if (response.status === 'left') {
            listPresenceConference(socket, accountCode);
          }
        },
      );
    }

    return {
      presenceForVideo,
      listPresenceConference,
      presenceConference,
    };
  },
);
