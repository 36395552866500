import { useUserStore } from '@/stores';
import axios from 'axios';
import type { AxiosError } from 'axios';
import type {
  ApiDataException,
  GetGroupResponseData,
  NewGroupResponseData,
} from '@/types';
import { EndpointApi } from '@/enums';

interface User {
  id: number;
  name: string;
}

interface NewUser {
  user_id: number;
}

export async function useGetGroup() {
  const userStore = useUserStore();
  try {
    const response = await axios.get<GetGroupResponseData>(
      EndpointApi.GetGroups,
    );
    const data = response.data.groups;

    userStore.groups = [];
    for (const row of data) {
      const users: User[] = [];
      for (const user of row.group_users) {
        const isActive = userStore.getUserIsActive(user.user_id);
        const name = userStore.getNameByUserId(user.user_id);

        users.push({
          id: user.user_id,
          name: isActive ? name : `${name} (deactivated)`,
        });
      }
      userStore.setGroup({
        id: row.id,
        users,
        name: row.name,
        hide: userStore.setHideGroup(row),
      });
    }
    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useNewGroup(users: User[], name: string) {
  try {
    const data: NewUser[] = [];
    for (const user of users) {
      data.push({
        user_id: user.id,
      });
    }
    const response = await axios.post<NewGroupResponseData>(
      EndpointApi.NewGroup,
      { users: data, name },
    );

    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useEditGroupName(name: string, groupId: string) {
  const url = `${EndpointApi.Group}/${groupId}/edit`;

  try {
    const response = await axios.put<NewGroupResponseData>(url, {
      name,
    });

    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}

export async function useHideGroup(hide: boolean, groupId: number) {
  const url = `${EndpointApi.Group}/${groupId}/hide`;

  try {
    const response = await axios.put<NewGroupResponseData>(url, {
      hide,
    });

    return response.data;
  } catch (err) {
    throw err as AxiosError<ApiDataException>;
  }
}
