import { useFileSystemAccess } from '@vueuse/core';
import type { Ref } from 'vue';
import { ref } from 'vue';

export function saveAudio(blob: Blob, filename: string) {
  const anchorElement = document.createElement('a');
  const url = URL.createObjectURL(blob);

  anchorElement.href = url;
  anchorElement.download = filename;
  document.body.appendChild(anchorElement);
  anchorElement.click();

  document.body.removeChild(anchorElement);
  URL.revokeObjectURL(url);
}

export async function openImage() {
  const dataType = ref('ArrayBuffer') as Ref<'Text' | 'ArrayBuffer' | 'Blob'>;
  const { open, file } = useFileSystemAccess({
    dataType,
    types: [
      {
        description: 'image',
        accept: {
          'image/*': ['.jpeg'],
        },
      },
    ],
    excludeAcceptAllOption: true,
  });

  try {
    await open();
    return file.value as File;
  } catch (e) {
    throw e as Error;
  }
}

export const convertBlobToBase64 = (blob: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export function formatBytes(bytes: number, decimals = 2) {
  if (Number(bytes) === 0) return '0 B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
