import { ref } from 'vue';
import { defineStore } from 'pinia';
import {
  useUserStore,
  useMessageStore,
  useSocketConnectionStore,
} from '@/stores';
import type { FileAttachments, FileUploadData } from '@/types';
import type { ChatMessageSocket } from '@/utils/db/types';

interface PosAttachment {
  dstUser: number;
  message?: string;
  files: FileUploadData[];
}

interface DeleteAttachment {
  dstUser: number;
  hash: string;
  createdAt: Date;
}

export interface FileMessage {
  hash: string;
  filename: string;
  filetype: string;
  size: string;
  deleted_at?: string;
}

interface Settings {
  forbiddenFileTypes: string[];
  maxSizeFileBytes: number;
}

export const useAttachmentStore = defineStore('attachment', () => {
  const fileMessage = ref<FileMessage[]>([]);
  const forbiddenFileTypes = ref<Settings['forbiddenFileTypes']>([]);
  const maxSizeFileBytes = ref<Settings['maxSizeFileBytes']>(0);

  function setForbiddenFileTypes(
    dataForbiddenFileTypes: Settings['forbiddenFileTypes'],
  ) {
    forbiddenFileTypes.value = dataForbiddenFileTypes;
  }

  function getExtensionByName(name: string) {
    const arr = name.split('.');
    const lastElementIndex = arr.length - 1;
    return arr[lastElementIndex];
  }

  function isForbiddenFileTypes(name: string) {
    const extension = getExtensionByName(name);
    return forbiddenFileTypes.value.includes(extension);
  }

  function setMaxSizeFileBytes(
    dataMaxSizeFileBytes: Settings['maxSizeFileBytes'],
  ) {
    maxSizeFileBytes.value = dataMaxSizeFileBytes;
  }

  function setSettingsAttachment(settings: Settings) {
    setForbiddenFileTypes(settings.forbiddenFileTypes);
    setMaxSizeFileBytes(settings.maxSizeFileBytes);
  }

  function setFileMessage(files: FileUploadData[]) {
    fileMessage.value = [];
    for (const file of files) {
      fileMessage.value.push({
        hash: file.hash,
        filename: file.name,
        filetype: file.filetype,
        size: file.size.toString(),
      });
    }
  }

  function postAttachment({ dstUser, files, message }: PosAttachment) {
    const socketStore = useSocketConnectionStore();
    const userStore = useUserStore();
    const { insertMessageFromSocket } = useMessageStore();
    const socket = socketStore.ioClient;

    setFileMessage(files);

    socket?.emit(
      'post-attachment',
      {
        src_user: userStore.user.userId,
        dst_user: dstUser,
        account_code: userStore.currentAccount.accountCode,
        message,
        files: fileMessage.value,
      },
      (response: ChatMessageSocket) => {
        insertMessageFromSocket(response);
      },
    );
  }

  function deleteAttachment({ dstUser, hash, createdAt }: DeleteAttachment) {
    const socketStore = useSocketConnectionStore();
    const userStore = useUserStore();
    const { insertMessageFromSocket } = useMessageStore();
    const socket = socketStore.ioClient;

    socket?.emit(
      'delete-attachment',
      {
        src_user: userStore.user.userId,
        dst_user: dstUser,
        account_code: userStore.currentAccount.accountCode,
        created_at: createdAt,
        hash,
      },
      (response: ChatMessageSocket) => {
        insertMessageFromSocket(response);
      },
    );
  }

  return {
    forbiddenFileTypes,
    maxSizeFileBytes,
    postAttachment,
    deleteAttachment,
    setSettingsAttachment,
    isForbiddenFileTypes,
  };
});
