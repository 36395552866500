import { defineStore } from 'pinia';
import { useUserStore } from '@/stores/user';
import { computed } from 'vue';
import { toastInfo } from '@/utils';
import { useSocketConnectionStore } from '@/stores/socket-connection';
import { useMessageStore } from '@/stores/chat-message';
import type { ChatMessageSocket, GroupMessage } from '@/utils/db/types';
import { useChatMessageVars } from '@/stores/chat-message-vars';
import { useRouter } from 'vue-router';
import { RouteName } from '@/enums';
import { useI18n } from 'vue-i18n';
import { useToastStore } from '@/stores/toast';
import { useImage } from '@/composables';
import { useIndexesStore } from '@/stores/indexes';
import { useGroupMessageStore } from '@/stores/group-message';

interface conferenceParams {
  invited_user_id: number;
  name: string;
  user_id: number;
  url: string;
  token: string;
  message: ChatMessageSocket;
}

interface NewConference {
  token: string;
  message: ChatMessageSocket;
}

interface ResponseNewRoomConference {
  token: string;
  error?: unknown;
}

interface UpdateConference {
  message: ChatMessageSocket;
}

export const useConferenceStore = defineStore('conference', () => {
  const socketConnectionStore = useSocketConnectionStore();
  const userStore = useUserStore();
  const ioClient = computed(() => socketConnectionStore.ioClient);
  const router = useRouter();
  const { t } = useI18n();
  const toastStore = useToastStore();
  const { imgBase64, setIconAvatar } = useImage();
  const indexesStore = useIndexesStore();
  const groupMessageStore = useGroupMessageStore();

  function openNewConferenceModal(token: string) {
    const baseUrl = window.location.origin;
    const url = router.resolve({
      name: RouteName.Conference,
      query: { room: token },
    });
    const newWindow = open(
      baseUrl + url.href,
      '_blank',
      'height=1024,width=768',
    );
    if (newWindow) {
      newWindow.onunload = () => {
        setTimeout(() => {
          if (newWindow.closed == true) {
            console.log('conference window was closed.');
            leftConference(token);
          }
        }, 400);
      };
    }
  }

  function newConferenceIO(invited_user_id: number) {
    const { insertMessageFromSocket } = useMessageStore();
    const messageVars = useChatMessageVars();
    ioClient.value?.emit(
      'new-conference',
      {
        invited_user_id,
        name: userStore.user.userName,
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
      },
      (conference: conferenceParams) => {
        insertMessageFromSocket(conference.message);
        messageVars.addUserConference(conference.message);
        openNewConferenceModal(conference.token);
      },
    );
  }

  function acceptConference({ message, token }: NewConference) {
    ioClient.value?.emit(
      'accept-conference',
      {
        message: message,
        user_id: userStore.user.userId,
      },
      (response: conferenceParams) => {
        updateConferenceMessage(response.message);
      },
    );
    openNewConferenceModal(token);
  }

  function declineConference(params: NewConference) {
    const messageVars = useChatMessageVars();
    ioClient.value?.emit(
      'decline-conference',
      {
        user_id: params.message.dst_user,
        name: userStore.user.userName,
        message: messageVars.getUserConference(params.message.id),
      },
      (params: conferenceParams) => {
        updateConferenceMessage(params.message);
      },
    );
  }

  function leftConference(room: string) {
    ioClient.value?.emit(
      'left-conference',
      {
        user_id: userStore.user.userId,
        account_code: userStore.currentAccount.accountCode,
        room,
      },
      (params: { message: GroupMessage | ChatMessageSocket }) => {
        if (checkObjectIsAGroupMessage(params.message)) {
          return groupMessageStore.saveMessage(params.message);
        }

        updateConferenceMessage(params.message);
      },
    );
  }

  function newRoomConference() {
    return new Promise<ResponseNewRoomConference>((resolve, reject) => {
      ioClient.value?.emit(
        'conference-new-room',
        (response: ResponseNewRoomConference) => {
          if (response.error) {
            reject(response.error);
          }

          resolve(response);
        },
      );
    });
  }

  function joinNewRoomConference(token: string) {
    ioClient.value?.emit('join-new-room', {
      account_code: userStore.currentAccount.accountCode,
      user_id: userStore.user.userId,
      room: token,
    });
    openNewConferenceModal(token);
  }

  function checkObjectIsAGroupMessage(obj: unknown): obj is GroupMessage {
    return Object.prototype.hasOwnProperty.call(obj, 'group_id');
  }

  function updateConferenceMessage(message: ChatMessageSocket) {
    const messageVars = useChatMessageVars();
    const { insertMessageFromSocket } = useMessageStore();

    messageVars.removeUserConference(message);
    insertMessageFromSocket(message);
  }

  function onListenersIO() {
    const messageVars = useChatMessageVars();
    ioClient.value?.on('open-conference', async (params: conferenceParams) => {
      const { insertMessageFromSocket } = useMessageStore();
      insertMessageFromSocket(params.message);
      messageVars.addUserConference(params.message);
      await setIconAvatar(params.message.src_user);
      const userName =
        indexesStore.directories[params.message.src_user].userName;
      const message = `Started a meeting`;
      toastStore.newMeeting({
        type: 'directory',
        title: userName,
        message,
        directoryConference: { token: params.token, message: params.message },
        id: window.crypto.randomUUID(),
        avatarUrl: imgBase64.value,
        variant: 'meeting',
      });
    });

    ioClient.value?.on('decline-conference', (params: conferenceParams) => {
      toastInfo(params.name + ' ' + t('declined conference'));
      updateConferenceMessage(params.message);
    });

    ioClient.value?.on('accept-conference', (params: conferenceParams) => {
      updateConferenceMessage(params.message);
    });

    ioClient.value?.on('update-meeting', (params: UpdateConference) => {
      updateConferenceMessage(params.message);
    });
  }

  return {
    newConferenceIO,
    onListenersIO,
    openNewConferenceModal,
    acceptConference,
    declineConference,
    leftConference,
    newRoomConference,
    joinNewRoomConference,
  };
});
