import type { RouteRecordRaw } from 'vue-router';
import { RouteName, RoutePath } from '@/enums';
import { loginFromArenaJWT } from '@/composables';

export const routes: RouteRecordRaw[] = [
  {
    path: RoutePath.Conference,
    name: RouteName.Conference,
    component: () => import('@/views/ConferenceView.vue'),
  },
  {
    path: RoutePath.ResetPassword,
    name: RouteName.ResetPassword,
    component: () => import('@/views/ResetPasswordView.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: RoutePath.CreateUser,
    name: RouteName.CreateUser,
    component: () => import('@/views/CompleteProfileView.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: RoutePath.Login,
    name: RouteName.Login,
    component: () => import('@/views/LoginView.vue'),
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: RoutePath.LoginFromArenaJWT,
    name: RouteName.LoginFromArenaJWT,
    component: () => ({}),
    beforeEnter: async (to, from, next) => {
      const token = to.params.jwt as string;

      await loginFromArenaJWT(token);

      next({ name: RouteName.Home });
    },
    meta: {
      hideForAuth: true,
    },
  },
  {
    path: '/',
    component: () => import('@/layouts/AuthLayout.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: RoutePath.Home,
        name: RouteName.Home,
        component: () => import('@/views/HomeView.vue'),
      },
      {
        path: RoutePath.Activity,
        name: RouteName.Activity,
        component: () => import('@/views/ActivityView.vue'),
      },
      {
        path: RoutePath.Group,
        name: RouteName.Group,
        component: () => import('@/views/GroupView.vue'),
      },
      {
        path: RoutePath.Directory,
        name: RouteName.Directory,
        component: () => import('@/views/DirectoryView.vue'),
      },
      {
        path: RoutePath.ContactList,
        name: RouteName.ContactList,
        component: () => import('@/views/ContactListView.vue'),
      },
      {
        path: RoutePath.Contact,
        name: RouteName.Contact,
        component: () => import('@/views/ContactView.vue'),
      },
      {
        path: RoutePath.Sms,
        name: RouteName.Sms,
        component: () => import('@/views/SmsView.vue'),
        /*meta: {
          toHome: import.meta.env.VITE_COUNTRY === 'US',
        },*/
      },
      {
        path: RoutePath.SendSms,
        name: RouteName.SendSms,
        component: () => import('@/views/SmsView.vue'),
      },
      {
        path: RoutePath.Search,
        name: RouteName.Search,
        component: () => import('@/views/SearchView.vue'),
      },
    ],
  },
  {
    path: RoutePath.NotFound,
    name: RouteName.NotFound,
    component: () => import('@/views/NotFound.vue'),
  },
];
